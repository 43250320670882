import { projects } from '../utils/data';

const Projects = () => {
  return (
    <div id='projects' className='mb-10'>
      <h2 className='text-[#f0ecec] text-lg md:text-2xl text-center mb-3 font-semibold'>
        Projects
      </h2>
      <p className='text-[#bdb9b9] text-center font-medium text-sm mb-5'>
        Some of My Projects
      </p>
      <div className=' h-full gap-6 w-full flex lg:grid lg:grid-cols-3 overflow-x-auto scroll-smooth no-scrollbar '>
        {projects.map((item) => (
          <div
            key={item.id}
            className='p-4 bg-[#8a87873d] w-[350px] min-w-[350px] min-h-[370px] rounded-xl overflow-hidden'
          >
            <a href={item.link} target='_blank' rel='noopener noreferrer'>
              <p className='text-[#f0ecec] text-lg font-semibold mb-3'>
                {item.title}
              </p>
              <div>
                <img
                  src={item.image}
                  alt='project'
                  className='w-full h-[200px] object-cover rounded-xl brightness-75'
                />
              </div>
              <p className='text-[#faeefe] text-sm font-medium my-3'>
                {item.description}
              </p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
