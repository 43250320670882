import Wek from '../assets/image/wek.PNG';
import Farm from '../assets/image/farm.PNG';
import Collab from '../assets/image/collab.PNG';
import Reposition from '../assets/image/reposition.PNG';
import FoodCourt from '../assets/image/foodcourt.PNG';
import Type from '../assets/image/type.PNG';

export const projects = [
  {
    id: 1,
    image: Type,
    title: 'Article',
    link: 'https://medium.com/@abiodunpeace8/why-typescript-is-better-than-javascript-13126c656051',
    description: 'Why Typescript is better than JavaScript. ( 3 mins read. )',
  },
  {
    id: 2,
    image: Reposition,
    title: 'Reposition',
    link: 'https://www.re-position.co/',
    description:
      'Official reposition store. Built using Next.js and Typescript',
  },
  {
    id: 3,
    image: FoodCourt,
    title: 'FoodCourt',
    link: 'https://www.getfoodcourt.com/',
    description:
      'Worked as a Frontend Engineer at FoodCourt. FoodCourt ia a restaurant and food delivery platform in Lagos, Nigeria. I built diverse web applications and features used by the company to serve their customers and meet business needs.',
  },
  {
    id: 4,
    image: Collab,
    title: 'Collab geek',
    link: 'https://collabgeek.co/',
    description:
      'A platform that showcases different creative collaborations from all over the world. Built using JavaScript and React.js.',
  },
  {
    id: 5,
    image: Wek,
    title: 'Wekbod',
    link: 'https://wekbod.netlify.app/',
    description:
      'This web application facilitates the connection between job seekers and their desired positions, helping them find their dream jobs.',
  },

  {
    id: 6,
    image: Farm,
    title: 'Olabisis Farm',
    link: 'https://olabisis-farm.netlify.app/',
    description:
      "A website for my mom's farm. From this platform, you have the convenience of purchasing fresh farm and livestock products, regardless of your location within Nigeria.",
  },
];
